@tailwind base;
@tailwind components;
@tailwind utilities;

p, li {
  margin-bottom: 10px;
}

header {
  z-index: 2;
}